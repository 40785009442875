<style lang="scss">
#app-countries {
  .modal-body {
    max-height: 80vh;
    min-height: 80vh;
    overflow-y: auto;
    padding: 0;
    .fixed-search {
      padding-top: 10px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      background: white;
      // height: 50px;
      position: sticky;
      top: 0;
      .form-control {
        height: 40px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        cursor: pointer;
        padding: 15px;
        border-bottom: 1px solid #ccc;
        &:hover {
          background: #7359dc10;
        }
        &.active {
          background: #7459dc;
          color: white;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<template>
  <b-modal
    id="app-countries"
    no-close-on-backdrop
    centered
    v-model="showModal"
    @show="close"
    :title="'Countries'"
  >
    <div class="input-group p-2 fixed-search">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fa fa-search"></i
        ></span>
      </div>
      <input
        v-model="searchInput"
        type="text"
        class="form-control"
        ref="countrysearch"
      />
    </div>
    <ul>
      <li
        v-for="(cItem, index) in filterCountries"
        :key="index"
        class="d-flex"
        :class="phone_code.code == cItem.code ? 'active' : ''"
        @click="updatePhoneCode(cItem)"
        :id="'scroll_li' + cItem.code"
      >
        <img :src="cItem.flag" alt="" class="mr-2" />
        <div style="margin-top:8px;width: 100%">
          <h5>{{ cItem.name }} ({{ cItem.dial_code }})</h5>
        </div>
        <!-- <h5 style="margin-top:5px;float: right; font-size: 16px; color: #4a4a4a">{{ cItem.dial_code}}</h5> -->
      </li>
    </ul>
    <template #modal-footer>
      <div class="w-100">
        <!-- <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button> -->
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
  SET_COUNTRY_PHONE_CODE,
  SET_PAYMENT_CARD_NUMBER_PHONE_CODE
} from "@/core/services/store/mutations.type";
export default {
  watch: {
    showModal: function() {
      if (this.showModal) {
        //         var flagJson = require("@/assets/json/countries_flags/24x24//flags-24x24.json");
        //        this.filterCountries.forEach(element => {
        //     var c =  element.code.toLowerCase();
        //     element.flag = flagJson[c];
        // });
        //   console.log(this.filterCountries);
        setTimeout(() => {
          document.getElementById(this.liScrolls).scrollIntoView({
            behavior: "instant",
            block: "center"
          });
          this.$refs.countrysearch.focus();
        }, 200);
      }
    }
  },
  computed: {
    ...mapGetters(["getSelectedCountryPhoneCode", "getPaymentCardPhonecode"]),

    filterCountries() {
      let tempCountries = this.countries;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempCountries = tempCountries.filter(item => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempCountries;
    }
  },
  data() {
    return {
      countries: [],
      flags: [],
      searchInput: "",
      phone_code: {
        code: "US"
      }
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    liScrolls: {
      type: String,
      default: ""
    },
    type: {
      type: Boolean,
      default: false
    }
  },
  created() {
    var countriesJson = require("@/assets/json/countries.json");
    this.countries = countriesJson;
    // this.countries = countries;
    if (this.type) {
      this.phone_code = this.getPaymentCardPhonecode;
    } else {
      this.phone_code = this.getSelectedCountryPhoneCode;
    }
  },
  methods: {
    updatePhoneCode(item) {
      if (this.type) {
        this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE_CODE, item);
        this.close();
      } else {
        this.$store.commit(SET_COUNTRY_PHONE_CODE, item);
        this.close();
      }
    },
    close() {
      this.$emit("closemodal", false);
    }
  }
};
</script>
